





























import { ColumnConfig } from '@/components/table/types'
import { Vue, Component } from 'vue-property-decorator'
import viewcomments from './viewcomments.vue'
@Component({
  components: {
    ViewComments: viewcomments
  }
})
export default class userInfo extends Vue {
  loading = false;
  tableData = [];
  creditNo = 0;
  publishTotal = 0;
  current = 1;
  dialogVisible = false;
  articleId: null | number = null;
  total = 0;
  column: Array<ColumnConfig> = [
    {
      label: '用户名',
      code: 'nickName'
    },
    {
      label: '发布标签',
      code: 'classificationLayer'
    },
    {
      label: '地点',
      code: 'address'
    },
    {
      label: '发布日期',
      code: 'publishTime'
    },
    {
      label: '发布内容',
      code: 'content'
    },
    {
      label: '操作',
      key: 'action',
      options: [
        {
          title: '查看评论',
          click: (row: any): void => {
            this.viewComments(row.id)
          }
        },
        {
          title: '删除',
          click: (row: any): void => {
            this.updateCommunity(row.id)
          }
        }
      ]
    }
  ];

  async updateCommunity (id: number) {
    await this.$Delete(this.$Api.deleteArticle, {
      id
    })
    this.fetch()
  }

  async viewComments (id: number) {
    this.dialogVisible = true
    this.articleId = id
  }

  routerBack (): void {
    this.$router.back()
  }

  changePagination (current: number) {
    this.current = current
    this.fetch()
  }

  async fetch (): Promise<void> {
    this.loading = true
    const [res, res2] = await Promise.all([
      this.$Get(this.$Api.userMoreTotal + '/' + this.$route.query.id),
      this.$Get(this.$Api.listPage4Web, {
        userId: this.$route.query.id,
        queryType: 2,
        current: this.current,
        pageSize: 10
      })
    ])
    this.tableData = res2.data.records
    this.total = res2.data.total
    this.creditNo = res.data.creditNo
    this.publishTotal = res.data.publishTotal
    this.loading = false
  }

  mounted () {
    this.fetch()
  }
}
