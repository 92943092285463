





















import { ColumnConfig } from '@/components/table/types'
import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator'
@Component
export default class viewComments extends Vue {
  @PropSync('dialogVisible', { type: Boolean, default: false })
  visible!: boolean;

  @Prop({ type: Number }) id!: number;

  current = 1;
  pageSize = 5;
  tableData = [];
  total = 0;

  column: Array<ColumnConfig> = [
    {
      label: '用户名',
      code: 'nickName'
    },
    {
      label: '发布标签',
      code: 'classificationLayer'
    }
  ];

  @Watch('visible')
  async changeVisible (newVal: boolean) {
    newVal && this.fetchList()
  }

  close (): void {
    this.visible = false
  }

  fetchList () {
    this.$nextTick(async () => {
      const res = await this.$Get(
        this.$Api.commentsListPage4Web,
        {
          current: this.current,
          pageSize: this.pageSize,
          id: this.id
        },
        {
          loading: true,
          target: '.el-dialog__body'
        }
      )
      this.tableData = res.data.records
      this.total = res.data.total
    })
  }

  changePagination (current: number): void {
    this.current = current
    this.fetchList()
  }
}
